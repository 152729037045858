:root {
    --primary-font: 'LouisGeorge';
    /* --primary-background-color: #e3e3e3a5; */
    /* --primary-background-color: red; */
    --primary-background-color: beige;
    --brackset-primary-color: #2c7399;
    --brackset-secondary-color: #3F915F;
    --brackset-error-color: #992c2c;
    --brackset-white-space-color: brown;
    --brackset-white-space-hover-color: azure;
    --secondary-background-color: #4a4d4e;
    --brackset-dark-theme-color: #253e4c;
    --svg-white-filter: invert(100%) sepia(9%) saturate(5300%) hue-rotate(292deg) brightness(114%) contrast(91%);

}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.teams-button {
    color: white;
    background-color: rgb(50, 50, 50);
    cursor: pointer;
    border-radius: 1rem;
    display: inline-block;
    padding: 0.5rem 1.5rem;
    border: none;
}

.masthead {
    height: 5rem;
    display: flex;
    justify-content: space-between;
    background-color: rgb(54, 54, 54);
    align-items: center;
}

.title-text {
    color: white;
    font-size: 2rem;
    padding: 1rem;
    height: 2rem;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 8rem;
}

.home-button {
    height: 80%;
    margin-right: 1rem;
    color: white;
    background-color: rgb(0, 0, 0);
    cursor: pointer;
    border-radius: 1rem;
    display: inline-block;
    border: none;
}