
@font-face {
    font-family: 'LouisGeorge';
    src: local('LouisGeorge'), url(./fonts/Louis-George-Cafe.ttf) format('truetype');
}

.noscroll { 
    overflow: hidden;
}

.information-icon{
    max-height: 1rem;
    padding: 0 0.25rem 0 0.75rem;
}

.season-toggle-header{
    display: flex;
    align-items: center;
    background-color: cornflowerblue;
    overflow-x: auto;
    min-width: 100%;
    justify-content: space-between;
}
.toggle-button{
    background-color: beige;
    flex-grow: 1;
    padding: 0.4rem 2rem;
}
.toggle-button:hover{
    background-color:antiquewhite;
    cursor: pointer;
}

.toggle-button.active{
    background-color: brown;
    color: white;
    border-radius: 0 0 1rem 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.4rem;
}

.display-tabs{
    display: flex;
    background-color: beige;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: auto;
    white-space: nowrap;
}

.display-tabs::-webkit-scrollbar {
    display: none;
}
.bgl-header{
    font-size: 2.6rem;
    padding: 0.8rem 0;
    background-color: brown;
    color: white;
    /* border-bottom: 0.01rem solid beige; */
    /* font-variant: petite-caps; */
    font-variant-caps: small-caps;
}

.season-toggle{
    /* width: 100%; */
    /* background-color: cornflowerblue; */
    display: flex;
    justify-content: flex-end;
    padding: 0.4rem 0rem;
}



.season-button{
    padding: 0.5rem 1.4rem;
    background-color: aquamarine;
    border-radius: 0.8rem;
    margin-left: 0.4rem;
}

.season-button:last-child{
    margin-right: 0.4rem;
}

.season-button.active{
    background-color: darkblue;
    color: white;
}

.coming-soon-message{
    font-size: 1.5rem;
    padding: 1rem;
}

@media only screen and (min-width: 768px) {    
    .season-button:hover{
        background-color: aliceblue;
        cursor: pointer;
        color: black;
    }

    .display-tabs{
        display: flex;
        justify-content: space-evenly;
        background-color: beige;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        overflow-x: auto;
        white-space: nowrap;
    }
}