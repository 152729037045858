
@font-face {
    font-family: 'LouisGeorge';
    src: local('LouisGeorge'), url(./fonts/Louis-George-Cafe.ttf) format('truetype');
  }

  .bgl-table-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bgl-power-cell{
    padding:0.1rem 0.5rem;
    text-align: left;
  }

  .power-ranking-header{
    padding: 2rem 0 0.4rem;
  }

  .week-toggle-container{
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 0.75rem;
    /* max-width: 90vw; */
    align-self: center;
    /* overflow: auto; */
    /* border-radius: 1rem 1rem 1rem 1rem; */
    /* scroll-snap-type: x mandatory; */
    flex-wrap: wrap;
    padding: 0 1rem;
  }

  .week-toggle-button{    
    background-color: beige;
    padding: 0.5rem 1rem;
    text-wrap: nowrap;
    scroll-snap-align: end;
    border-radius: 1rem;
    margin: 0.25rem;
  }

  .week-toggle-button.active{
    background-color: brown;
    color: white;
  }

  .week-toggle-button:last-child{
    /* border-radius: 0 1rem 1rem 0; */

  }
  .week-toggle-button:first-child{
    /* border-radius: 1rem 0 0 1rem; */

  }

  .chart-container{
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chart-container:last-child{
    padding: 0 1rem 1rem;
  }

  /* .bgl-power-cell.name-cell{
    width: 6rem;
    text-align: center;
  } */

  .bgl-power-cell.positive{
    color: green;
  }
  .bgl-power-cell.positive::before{
    content: '\25B2';
  }

   

   .bgl-power-cell.negative{
    color: brown;
  }
  .bgl-power-cell.negative::before{
    content: '\25BC';
  }
  

@media only screen and (min-width: 768px) {    
    .week-toggle-button:hover{
        background-color: antiquewhite;
        cursor: pointer;
        color: black;
    }
    .week-toggle-button.active:hover{
        background-color: brown;
        color: white;
    }
}