.detail-panel-header{
    background-color: var(--brackset-white-space-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    font-size: 1.5rem;
    padding: 1rem;
    border-bottom: 1px solid black;
    color: white;
}
.detail-header-spacer{
    width: 2.75rem;
}
.detail-panel-back-button{    
    max-width: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    transform: rotate(180deg);
}

.detail-panel-background{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    -webkit-transition: all .12s ease;
    -moz-transition: all .12s ease;
    transition: all .12s ease;
}
.detail-panel-background.hide{
    height: 0%;
    width: 0%;
    top: 50%;
    left: 50%;
}
.detail-panel{    
    z-index: 1;
    background-color: var(--primary-background-color);
    box-shadow: 1px 1px 8px black;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1;  */
    /* min-width: 30rem; */
}
.detail-panel-button-container{
    display: flex;
    justify-content: center;
    background-color: rgb(25,25,25);
    margin: 0 -1rem -1rem -1rem;
    padding: 0.5rem 0 1rem 0;
    border-top: 3px solid cornsilk;
}


.close-button{
    max-height: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.settings-panel{
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    top: 0;
    left:0;
    background-color: rgba(0,0,0,0.5);
}

.detail-panel-contents.no-padding{
    padding: unset;
}

.detail-panel-contents{
    padding: 1rem 1.8rem;
    overflow-y: auto;
}

.detail-panel > .close-button-container > .close-button {
    padding: 0.5rem;
}

@media only screen and (max-width: 600px) {
	.detail-panel{
		/* max-width: 95%; */
	}
}


@media screen and (max-width: 700px) {
    .input-container{
        padding: 0.5rem;
    }

    .detail-panel{    
        height: 100%;
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (min-width: 700px){
    
    .detail-panel-background.right{
        justify-content: flex-end;
    }

    .detail-panel-background.right .detail-panel{    
        margin-right: 1rem;
        max-width: 80%;
        height: 95%;
    }

    .detail-panel-background.left{
        justify-content: flex-end;
    }

    .detail-panel-background.left .detail-panel{    
        margin-left: 1rem;
        max-width: 80%;
        height: 95%;
    }

    .detail-panel-background.center{
        justify-content: center;
    }

    .detail-panel-background.center .detail-panel{    
        max-width: 80%;
        height: 95%;
    }
    .detail-panel{    
        min-width: 30rem;
    }
}