/* body {
    display: flex;
    background: #ef62da;
    min-height: 100vh;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
} */

.calendar-container {
    background: #fff;
    /* width: 450px; */
    border-radius: 10px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
}

.calendar-container header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    justify-content: space-between;
}

header .calendar-navigation {
    display: flex;
}

header .calendar-navigation span {
    height: 38px;
    width: 38px;
    margin: 0 1px;
    cursor: pointer;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
    user-select: none;
    color: #aeabab;
    font-size: 1.9rem;
}

.calendar-navigation span:last-child {
    margin-right: -10px;
}

header .calendar-navigation span:hover {
    background: #f2f2f2;
}

header .calendar-current-date {
    font-weight: 500;
    font-size: 1.45rem;
}

.calendar-body {
    padding: 0 1rem 1rem;
}

.calendar-body ul {
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    text-align: center;
    padding-inline-start: unset;
}

.calendar-body .calendar-dates {
    margin-bottom: 20px;
}

.calendar-body li {
    width: calc(100% / 7);
    font-size: 1.07rem;
    color: #414141;
}

.calendar-body .calendar-weekdays li {
    cursor: default;
    font-weight: 500;
}

.calendar-body .calendar-dates li {
    margin-top: 30px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.calendar-dates li.inactive {
    color: #aaa;
}

.calendar-dates li.active {
    color: #fff;
}
.calendar-dates li.game-window-date {
    color: #fff;
}
.calendar-dates li.active-date {
    color: #fff;
}

.calendar-dates li::before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.calendar-dates li.active::before {
    background: #6332c5;
}
.calendar-dates li.game-window-date::before {
    background: #6332c5;
}
.calendar-dates li.active-date::before {
    background: #6332c5;
}

.calendar-dates li:not(.active):hover::before {
    background: #e4e1e1;
}